import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import cookies from '../../services/cookies';
import Graphql from '../../services/graphql';

function UserMiddleware() {
  const router = useRouter();
  const { setNewCookie, deleteCookie } = cookies();
  const [token, setNewToken] = useState();
  const { gqlQuery, gqlMutation } = Graphql(token);
  const time = 60 * 60 * 2; // 2 hours

  const login = useCallback(async (email, password) => {
    const collectionParams = 'input : { identifier: $identifier, password: $password}';
    const collectionReturns = 'jwt user { id } ';
    const mutationParams = '$identifier: String!, $password: String!';
    const variables = { identifier: email, password };
    const args = {
      variables,
      mutationParams
    };

    try {
      const response = await gqlMutation('login', collectionParams, collectionReturns, args);

      if (response.data) {
        const { data } = response;
        setNewCookie('server', 'token', data?.login?.jwt, time, '/');
        setNewToken(data?.login?.jwt);

        const newArgs = {
          variables: { id: data?.login?.user?.id },
          queryParams: '$id : ID!'
        };

        const userInfo = await gqlQuery(
          'user',
          'id: $id',
          `
            id
            name
            email
            blocked
            firstLogin
            created_at
            role { id name }
            user_integrations { id api_key integration { name } }
            recruiter {
              id
              name
              uuid
              cnpj
              email
              about
              admin
              phone
              subname
              youtube
              imageUrl
              created_at
              recruiter_network{ id }
            }
            employee {
              id
              name
              cnpj
              email
              imageUrl
              phone
              user { id }
            }
            admin {
              id
              name
              cnpj
              email
              imageUrl
              phone
              user { id }
            }
          `,
          newArgs
        );

        const userData = userInfo?.data?.user;
        const firstLogin = { status: userData?.firstLogin };
        const userTypeRole = userData?.role?.name;

        if (!['Empresa', 'Recrutador', 'Engenheiro'].includes(userData?.role?.name)) {
          throw new Error('Área indevida para acesso');
        }

        const employeeCaseName = userData?.employee ? userData?.name : null;
        const userIntegrationsObject = userData?.user_integrations?.reduce(
          (oldValues, integrationItem) => {
            const {
              integration: { name },
              ...restOfIntegrationItem
            } = integrationItem;

            return {
              ...oldValues,
              [name?.toLowerCase()]: restOfIntegrationItem
            };
          },
          {}
        );

        const user = {
          id: userData?.id,
          firstLogin: userData?.firstLogin,
          integrations: userIntegrationsObject ?? {},
          name: employeeCaseName || userData?.admin?.name || userData?.recruiter?.name,
          subname: userData?.recruiter?.subname,
          image:
            userData?.employee?.imageUrl ||
            userData?.admin?.imageUrl ||
            userData?.recruiter?.imageUrl,
          email: userData?.employee?.email || userData?.admin?.email || userData?.recruiter?.email,
          phone: userData?.employee?.phone || userData?.admin?.phone || userData?.recruiter?.phone,
          createdAt:
            userData?.employee?.created_at ||
            userData?.admin?.created_at ||
            userData?.recruiter?.created_at,
          about: userData?.employee?.about || userData?.admin?.about || userData?.recruiter?.about,
          youtube:
            userData?.employee?.youtube || userData?.admin?.youtube || userData?.recruiter?.youtube
        };

        const userTypes = {
          Empresa: 'company',
          Recrutador: 'recruiter',
          Candidato: 'applicant',
          Engenheiro: 'engineer'
        };

        const userType = {
          type: userTypes[String(userTypeRole)],
          isCoder: userTypeRole === 'Engenheiro',
          typeId: userData?.employee?.id || userData?.admin?.id || userData?.recruiter?.id,
          name: employeeCaseName || userData?.admin?.name || userData?.recruiter?.name,
          cnpj:
            userData?.employee?.cnpj || userData?.admin?.cnpj || userData?.recruiter?.cnpj || '',
          admin: userData?.recruiter?.admin || false,
          uuid: userData?.recruiter?.uuid || null,
          imageUrl:
            userData?.employee?.imageUrl ||
            userData?.admin?.imageUrl ||
            userData?.recruiter?.imageUrl,
          createdAt: userData?.created_at,

          ...(userTypes[String(userTypeRole)] === 'recruiter' && {
            recruiter_network: {
              id: userData?.recruiter?.recruiter_network?.id
            }
          }),
          ...(userTypes[String(userTypeRole)] === 'recruiter' && {
            uuid: userData?.recruiter?.uuid
          })
        };

        setNewCookie('server', 'user', JSON.stringify(user), time, '/');
        setNewCookie('server', 'userType', JSON.stringify(userType), time, '/');
        setNewCookie('server', 'firstLogin', firstLogin, time, '/');

        return { user, userType };
      }

      throw new Error('Usuário não encontrado');
    } catch (error) {
      console.error('Login:', error);

      return {};
    }
  }, []);

  const logout = useCallback((redirectUrl = '/') => {
    try {
      deleteCookie('client', 'user');
      deleteCookie('client', 'userType');
      deleteCookie('client', 'token');

      setNewToken('');

      sessionStorage?.clear();
      localStorage?.clear();

      router.push(redirectUrl);
    } catch (error) {
      console.error('logout error:', error.message);
    }
  }, []);

  const register = useCallback(async (user) => {
    try {
      const registered = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/auth/local/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...user })
      });

      const response = await registered.json();

      if (response?.errors) {
        throw new Error('Erro ao criar o usuário. Tente novamente.');
      }

      const userInfo = {
        id: response?.user.id,
        email: response?.user.email,
        phone: response?.user?.phone,
        username: response?.user?.username
      };

      setNewCookie('server', 'token', response?.jwt, time);
      setNewCookie('server', 'registered', JSON.stringify(userInfo), time);
      setNewCookie('server', 'firstLogin', true, time);

      return response?.user;
    } catch (error) {
      console.error('Erro ao criar usuário:', error);

      return {};
    }
  }, []);

  return {
    login,
    logout,
    register
  };
}

export default UserMiddleware;
